// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
@import "~antd/dist/antd.less";
// theme overrides
@primary-color: #4E6CFF; //@blue-5;
@border-radius-base: 3px;
@background-color-base: #f1f5f9; //#fafafa;
@table-row-hover-bg: rgba(225,245,254 , 0);
@table-row-hover-bg-custom: rgba(225,245,254 , 0.2);
@font-family: 'Open Sans',Helvetica,sans-serif;
@tab-bar-margin: 0;
@layout-body-background: #FFFFFF;
@layout-sider-background: #FFFFFF;
@alert-info-border-color: rgba(0,0,0,0);

@import "./spacing.less";
@import "./utilities.less";
@import "./prism.less";

@title-color: #121212;

html, body {
  background-color: white; //#f2f2f3; //#ececec;
  font-family: @font-family;
  font-size: 14px;
}

#root {
  width: 100%;
  min-height: 100vh;
}

.ant-table {
  background: none;
}

.no-header-bg .ant-table-thead > tr > th {
  background: none;
}

.ant-table-tbody > tr:hover > td {
  border-bottom: 1px solid rgba(78,108,255,0.4);
}

.ant-tag {
  line-height: 22px;
  height: 24px;
  // vertical-align: top;
  &.small {
    font-size: 12px;
    line-height: inherit;
    height: inherit;
  }
  &.xsmall {
    font-size: 10px;
    line-height: inherit;
    height: inherit;
  }
  &.large {
    font-size: 16px;
    line-height: inherit;
    height: inherit;
  }
  border-left-width: 2px;
  border-top: none;
  border-right: none;
  border-bottom: none;
}

// copied from core base.less
.link {
  color: @link-color;
  background: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color .3s ease;

  &:hover {
    color: @link-hover-color;
  }

  &:active {
    color: @link-active-color;
  }

  &:active,
  &:hover {
    outline: 0;
    text-decoration: none;
  }

  &[disabled] {
    color: #ccc;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.splash {
  min-height: 100vh;
  // background: linear-gradient(to right, #512DA8, #673AB7);
  // background: linear-gradient(to right, #00c6ff, #0072ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
background: #536976;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #292E49, #536976);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #292E49, #536976); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  padding-bottom: @space-xl;

  &-logo {
    display: block;
    margin: 0 auto;
    padding: @space-xl 0;
    width: 150px;
  }

  &-content {
    background-color: white;
    max-width: 540px;
    margin: 0 auto;
    padding: @space-l;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
    overflow: hidden;
  }

  .google-svg {
    vertical-align: middle;
    margin-right: @space-m;
  }

  &-layout {
    display: table;
    width: 100%;
    table-layout: fixed;
  
    &-steps {
      display: table-cell;
      vertical-align: top;
      border-right: 1px solid black;
      width: 220px;
    }

    &-content {
      display: table-cell;
      vertical-align: top;
      padding-left: @space-l;
    }
  }
}


.list {
  // border: 1px solid @border-color-base;
  // border-radius: @border-radius-base;
  display: table;
  width: 100%;
  box-shadow: @box-shadow-base;

  &-item {
    display: table-row;

    > div {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      padding: @space-m;
      position: relative;
      color: inherit;
    }

    &:hover {
      // background-color: #eaf8fe;
      background-color: rgba(3,169,244,0.05);
    }

    &:not(:last-child) > div {
      border-bottom: 1px solid @border-color-split;
    }

    &-title {
      font-size: 18px;
    }

    &-icon {
    }
  }
}

.layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  position: relative;
  min-height: 100vh;
}

.layout-content {
  padding-bottom: @space-l;
}

.layout-aside {
  position: relative;

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;

  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.layout-header {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 60px;
  background: #536976;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #292E49, #536976);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #292E49, #536976); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  // background-color: #4E6CFF;
  // background: linear-gradient(to right, #512DA8, #673AB7);
  // background-image: linear-gradient(-225deg, #2340CE 0%, #55B7E0 100%);

  &::after {
    clear: both;
  }

  a, a:visited {
    color: white;
  }

  &-logo {
    display: block;
    float: left;
    transition: all 0.3s ease;
    padding-top: 15px;
    text-align: center;
    width: 220px;
    &.folded {
      width: 70px;
    }
  }

  &-breadcrumb {
    display: block;
    float: left;
    padding-left: @space-m;
    height: 60px;
    line-height: 60px;
  }

  &-bell {
    font-size: 18px;
  }

  &-item {
    height: 60px;
    line-height: 60px;
    color: white;
    display: block;
    float: right;
    padding: 0 @space-m;
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &-user-container {
    display: inline-block;
    float: right;
    height: 60px;
  }

  &-user {
    display: table;
    color: white;
    padding: 0 @space-l;
    transition: all 0.3s ease;
    cursor: pointer;
    
    &-picture {
      display: table-cell;
      line-height: 60px;
      padding-right: @space-m;
    }

    &-name {
      display: table-cell;
      line-height: 60px;

      &-top {
        display: inline-block;
        line-height: 14px;
        vertical-align: middle;
      }
      &-sub {
        font-size: 10px;
      }
    }

    &-button {
      display: table-cell;
      line-height: 60px;
      padding-left: @space-m;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.layout-aside .layout-sider {

  background-color: #F6F9FC;
  border-right: 2px solid #D6DCF1;
  flex: 0 0 220px;
  max-width: 220px;
  min-width: 220px;
  width: 220px;
  position: relative;
  padding: 24px 0;
  // padding: 24px 24px 36px 24px;
  overflow: visible;
  transition: all 0.3s ease;

  &.folded {
    width: 70px;
  }
}

.layout-sider.collapse {
  flex: 0 0 70px;
  max-width: 70px;
  min-width: 70px;
  width: 70px;

  .layout-menu-item svg, .layout-menu-item .anticon {
    margin: 0;
  }

  .layout-menu-submenu {
    display: none;
  }

  .layout-menu-item-chevron {
    display: none;
  }
}

.layout-sider.collapse .layout-menu-hider {
  left: 68px;
}


.layout-main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  overflow-x: hidden;
  transition: all 0.3s ease;

  h1 {
    position: relative;
    font-size: 24px;
    padding: @space-m;
    border-bottom: 1px dashed @border-color-base;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;

    .actions {
      position: absolute;
      height: 36px;
      right: @space-m;
      top: @space-m;
      text-align: right;
      line-height: 36px;
      font-size: 14px;
      font-weight: normal;
      color:  @text-color;
    }
  }

  h2 {
    position: relative;
    text-transform: uppercase;
    font-size: 14px;
    color: #78909C;
    padding: @space-m;
    margin: 0;

    .actions {
      position: absolute;
      height: 32px;
      right: @space-m;
      top: @space-m;
      text-align: right;
      line-height: 28px;
      font-size: 14px;
      font-weight: normal;
      color:  @text-color;
    }
  }
}

.layout-menu {
  &-title {
    font-size: 12px;
    padding: 0 24px 12px 24px;

    .ant-select > .ant-select-selector {
      background: none;
      border-style: dashed;
      border-color: #353535;
    }
  }

  &-submenu {
    background-color: #edf0f2;
    padding: 6px 0;
  }

  &-subitem {
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    overflow: hidden;
    padding: 0 24px;
    // border-bottom: rgba(0, 0, 0, 0.05);

    &:hover, &.active {
      color: @primary-color;

      svg, .anticon {
        color: @primary-color;
      }
    }
  }

  &-item {
    // padding: 0 28px;
    position: relative;
    font-size: 15px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #353535;
    overflow: hidden;
    padding: 0 24px;

    &:hover, &.active {
      color: @primary-color;

      svg, .anticon {
        color: @primary-color;
      }
    }

    svg, .anticon {
      color: #546E7A;
      font-size: 14px;
      margin-right: 8px;
      width: 14px !important;
    }

    &-chevron {
      position: absolute;
      margin-top: 12px;
      right: 6px;
    }
  }

  &-hider {
    z-index: 10;
    position: fixed;
    bottom: 5px;
    left: 218px;
    line-height: 36px;
    color: white; //#B0BEC5;
    text-align: center;
    text-transform: uppercase;
    font-weight: 200;
    background-color: #B6BBCF; //rgba(0, 0, 0, 0.5);
    cursor: pointer;
    border-radius: 0px 3px 3px 0px;
    padding: 0 12px;

    &:hover {
      background-color: #9EA2B5;
      color: white;
      transition: all .3s ease;
    }
  }
}

.breadcrumb {
  color: rgba(255, 255, 255, 0.7);

  a, a:visited {
    color: white;
  }

  &-spacer {
    padding: 0 @space-s;
  }
}

pre.code {
  word-break: 'break-all';
  word-wrap: 'break-word';
  white-space: 'pre-wrap';
  border-radius: 3px;
}

.size-10 { font-size: 10px;}
.size-12 { font-size: 12px;}
.size-13 { font-size: 13px;}
.size-14 { font-size: 14px;}
.size-15 { font-size: 15px;}
.size-16 { font-size: 16px;}
.size-18 { font-size: 18px;}
.size-20 { font-size: 20px;}
.size-22 { font-size: 22px;}
.size-24 { font-size: 24px;}

.semibold { font-weight: 600; }
.vertical-center {
  vertical-align: middle !important;
}

.widget-title {
  color: #78909C;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
  line-height: 20px;
}

.dashed-bottom-2 { border-bottom: solid 2px #CFD8DC; }
.dashed-bottom-1 { border-bottom: dashed 1px #CFD8DC; }
.dashed-top-1 { border-top: dashed 1px #CFD8DC; }
.dashed-top-2 { border-top: solid 2px #CFD8DC; }
.dashed-right-1 { border-right: dashed 1px #CFD8DC; }
.dashed-right-2 { border-right: solid 2px #CFD8DC; }
.dashed-left-2 { border-left: solid 2px #CFD8DC; }

.border-bottom-2 { border-bottom: solid 2px #CFD8DC; }
.border-bottom-1 { border-bottom: solid 1px #CFD8DC; }
.border-top-1 { border-top: solid 1px #CFD8DC; }
.border-top-2 { border-top: solid 2px #CFD8DC; }
.border-right-1 { border-right: solid 1px #CFD8DC; }
.border-right-2 { border-right: solid 2px #CFD8DC; }
.border-left-1 { border-left: solid 1px #CFD8DC; }
.border-left-2 { border-left: solid 2px #CFD8DC; }

.color-blue { color: rgba(3, 178, 214, 1); }
.color-pink { color: rgba(233, 30, 99, 1); }
.color-purple { color: rgba(103, 58, 183, 1); }
.color-orange { color: rgba(255, 87, 34, 1); }
.color-teal { color: rgba(0, 150, 136, 1); }
.color-lime { color: rgba(139, 195, 74, 1); }
.color-black-40 { color: rgba(0,0,0, 0.4); }
.color-black-50 { color: rgba(0,0,0,0.5); }
.color-green { color: #4CAF50; }
.color-red { color: #F44336; }
.color-orange { color: #FF9800; }
.color-grey { color: #78909C; }

.corner-blue { border-left-color: rgba(3, 178, 214, 1) !important;  }
.corner-pink { border-left-color: rgba(233, 30, 99, 1) !important;  }
.corner-purple { border-left-color: rgba(103, 58, 183, 1) !important;  }
.corner-orange { border-left-color: rgba(255, 87, 34, 1) !important;  }
.corner-teal { border-left-color: rgba(0, 150, 136, 1) !important;  }
.corner-lime { border-left-color: rgba(139, 195, 74, 1) !important;  }

.opacity-30 { opacity: 0.3; }
.opacity-50 { opacity: 0.5; }
.opacity-60 { opacity: 0.6; }

.user-picture {
  width: 24px;
  height: 24px;
  border-radius: 50%;

  &.medium {
    width: 65px;
    height: 65px;
  }

  &.large {
    width: 80px;
    height: 80px;
  }
}

.user-picture-icon {
  font-size: 24px;
  line-height: 24px;
  opacity: 0.3;

  &.medium {
    font-size: 65px;
    line-height: 65px;
  }

  &.large {
    font-size: 80px;
    line-height: 80px;
  }
}

.ant-layout-has-sider .ant-layout-content {
  width: 70%;
}

.no-pointer {
  cursor: inherit;
}

.no-select {
  user-select: none;
}


.block-channel {
  background-color: white;
  border-radius: 3px;
  margin-bottom: @space-l;
  position: relative;
  box-shadow: 0 3px 6px 1px rgba(0,0,0,.08), 0 7px 14px 1px rgba(50,50,93,.1);

  &-title {
    border-bottom: 1px solid #f2f2f3;
    padding: @space-m 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 15px;
    font-weight: bold;
    color: @heading-color;

    .actions {
      float: right;
      font-weight: normal;
    }
  }

  &-logo img {
    height: 30px;
  }
}

.block-notif {
  background-color: white;
  border-radius: 3px;
  margin-bottom: @space-l;
  position: relative;
  box-shadow: 0 3px 6px 1px rgba(0,0,0,.08), 0 7px 14px 1px rgba(50,50,93,.1);
  // box-shadow: 0 0px 2px rgba(0, 0, 0, 0.1);

  &-content {
    position: relative;
    margin: 0 @space-m;
    padding: @space-m 0;
  }

  &-subtitle {
    padding: @space-s;
    font-size: 12px;
    font-weight: bold;
    // color: black;
    color: rgba(0,0,0,0.5);
    text-transform: uppercase;
    // border-bottom: 1px solid @border-color-split;
  }

  &-title {
    position: relative;
    border-bottom: 1px solid #f2f2f3;
    padding: @space-m 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 15px;
    font-weight: bold;
    color: @heading-color;

    .actions {
      position: absolute;
      text-align: right;
      right: @space-m;
      font-weight: normal;
      width: 300px;
    }
  }
}


.bar {
  position: relative;
  height: 70px;
  background-image: linear-gradient(87deg, #CFD9DF 0%, #E2EBF0 100%);
  line-height: 70px;
  padding: 0 32px;

  .name {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .right {
    position: relative;
    display: inline-block;
    float: right;
    top: 5px;
    right: 24px;
    z-index: 0;

    .badge {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0,0,0,.85);
      font-variant: tabular-nums;
      list-style: none;
      -webkit-font-feature-settings: "tnum";
      font-feature-settings: "tnum";
      position: relative;
      display: inline-block;
      color: unset;
      line-height: 1;

      &-count {
        z-index: auto;
        min-width: 20px;
        height: 20px;
        padding: 0 6px;
        color: #fff;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
        text-align: center;
        background: #ff4d4f;
        border-radius: 10px;
        -webkit-box-shadow: 0 0 0 1px #fff;
        box-shadow: 0 0 0 1px #fff;

        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%,-50%);
        transform-origin: 100% 0;
      }
    }
    .bell {
      color: #444C60;
      font-size: 26px;
    }
  }
}


@keyframes pulsing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}

.widget {
  position: absolute;
  right: 0;
  display: inline-block;
  margin: -30px 68px 0 0;
  z-index: 1;
  background: #FFFFFF;
  box-shadow: 2px 2px 30px 0 rgba(0,0,0,0.15);
  border-radius: 4px;
  width: 100%;
  max-width: 430px;
  line-height: 1.6em;
  text-align: left;

  .link, .link:visited {
    color: #00AFEC;
    cursor: pointer;
  }

  .link:hover {
    color: #007AA4;
  }

  &-header {
    padding: 12px;
    border-bottom: 1px solid #D0D0D0;

    &-title {
      font-size: 14px; 
      font-weight: 500;
      color: #757C85;
    }
  }

  &-header-right, &-footer-right {
    float: right;
    font-size: 14px;
  }

  &-footer {
    padding: 12px;
    font-size: 14px;
    border-top: 1px solid #D0D0D0;
  }
  &-footer::after {
    clear: both;
    content: '';
    display: table;
  }
  .item {

    &:hover {
      background-color: rgba(0,0,0,0.02);
      cursor: pointer;
    }

    position: relative;
    display: table;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
        // border-bottom: 1px solid #D0D0D0;

    .cover-container {
      display: table-cell;
      width: 52px;
      padding: 12px;
    }

    .cover {
      height: 52px;
      width: 52px;
      overflow: hidden;
      background-size: cover;
      border-radius: 50%;
    }

    &-content {
      display: table-cell;
      vertical-align: top;
      padding: 12px 12px 12px 0;
      width: 100%;
    }
    

    .unread {
      position: absolute;
      right: 16px;
      top: 35px;
      vertical-align: middle;
      width: 6px;
      height: 6px;
      background-color: #0091FF;
      border-radius: 6px;
      display: inline-block;
      line-height: inherit;
    }

    .unread::after {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid #0091FF;
      border-radius: 50%;
      animation: pulsing 1.2s infinite ease-in-out;
      content: '';
    }

    .title {
      color: #444C60;
      font-weight: bold;
    }

    .tag {
      font-size: 11px;
      font-weight: 400;
      text-transform: uppercase;
      border-radius: 4px;
      padding: 3px 6px;

      &.cyan {
        color: #13C2C2;
        background: #E6FFFB;
      }
      &.red {
        color: #F5222D;
        background: #FFF1F0;
      }
    }

    .desc {
      font-size: 14px;
      line-height: 1.2em;
      color: #444C60;
    }

    .time {
      font-size: 13px;
      color: #757C85;
      line-height: 13px;
      padding-top: 4px;
    }
  }
}

.ace-input {
  border: 1px solid @border-color-base;
  border-radius: @border-radius-base;
  // transition: all 0.3s;

  &:hover {
    border-color: @input-hover-border-color;
  }
}

.subtitle {
  color: #78909c;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
  line-height: 20px;
  user-select: none;
}

.kpi {
  padding: @space-l;

  .value {
    font-weight: 600;
    color: #4a4a4a;
    font-size: 18px;
    margin: 8px 0;
    user-select: none;
  }
}


.Iframe {
  border: 1px solid @border-color-base;
  border-radius: @border-radius-base;
  position: relative;
  // z-index: 10;
    background-color: white;
  
  &-normal {
    height: 200px;
    width: 100%;
  }
  &.expanded {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100% !important;
    border: none;
    border-radius: 0;
    z-index: 100;
  }

  &-actions {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
  }
}

.warning-box {
  background-color: #fffbe6;
  padding: @space-m;
  font-size: 15px;
  font-weight: 400;

  &:not(:last-child) {
    border-bottom: 1px solid @border-color-split;
  }
}

.paper {
  box-shadow: 0 3px 6px 1px rgba(0,0,0,.08), 0 7px 14px 1px rgba(50,50,93,.1);
}

.phone-preview {
  margin: 0 auto;
  width: 350px;
  height: 663px;
  overflow: hidden;
  background-image: url('/images/phone.png');

  .sms-container {
    width: 250px;
    margin: 100px 0 0 50px;
  }
  .title {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: @space-s;
  }
  .sms {
    background-color: rgba(255,255,255,0.9);
    padding: @space-s;
    border-radius: 8px;
    font-size: 12px;
  }
}


.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 12px;
}

.key-value-table {
  width: 100%;

  > tbody > tr:not(:last-child) > td, > tbody > tr:not(:last-child) > th {
    // border-bottom: 1px solid #CFD8DC;
  }
  
  > tbody > tr:nth-child(odd) {
    background-color: rgba(236,239,241,0.3);
  }

  > tbody > tr > th, > tbody > tr > td {
    vertical-align: top;
    padding: @space-s @space-m;
  }

  > tbody > tr > th {
    text-align: right;
    // background-color: rgba(236,239,241,0.3);
    font-weight: 500;
  }
}

